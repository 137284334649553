<template>
  <div class="manage-app-banners">
    <div class="container fluid">
      <h2 class="p-2 mb-3">Manage App Banners</h2>

      <banner
        v-if="banners.length > 8"
        class="banner-warn mb-3"
        :class="{
          'bg-warn fg-white': banners.length > 10,
          'bg-blue-100 fg-grey-800': banners.length < 10
        }"
      >
        There are currently
        <span class="font-bold">{{ banners.length }}</span> banners. It is
        recommended not to exceed <span class="font-bold">10</span> banners.
      </banner>

      <!-- Action-->
      <div class="flat card d-flex justify-content-between p-2 mb-2">
        <div class="row align-items-center">
          <label class="label mr-2">
            <i class="fas fa-sort-amount-down mr-1"></i>
            Sort Mode
          </label>
          <toggle-switch v-model="sortMode"></toggle-switch>
        </div>
        <fd-button class="main medium" @click="addNewBanner">
          <i class="fas fa-plus"></i>
          <span class="xs-d-none ml-1">Add New Banner</span>
        </fd-button>
      </div>

      <!-- Warn unsaved position change -->
      <banner
        v-if="hasRepositioned && !isLoading"
        class="bg-secondary fg-white p-2"
        :dismissable="false"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <i class="fas fa-exclamation-triangle mr-2"></i>
            You have not saved after sorting the banners.
          </div>
          <div>
            <fd-button class="bordered white" @click="resetPosition"
              >Reset</fd-button
            >
            <fd-button
              class="bg-white fg-secondary ml-2"
              @click="saveNewPosition"
              >Save</fd-button
            >
          </div>
        </div>
      </banner>

      <!-- ========================== Banner List ========================== -->
      <!-- Loading State -->
      <div v-if="isLoading">
        <app-banner-card v-for="n in 3" :key="n" isLoading></app-banner-card>
      </div>

      <!-- No Data -->
      <div v-else-if="banners.length < 1" class="card p-3">
        <no-data message="No banner has been created yet."></no-data>
      </div>
      <!-- Listing -->
      <div v-else>
        <fd-button
          v-if="sortMode && hasRepositioned"
          class="fixed medium round main"
          @click="saveNewPosition"
        >
          <i class="fas fa-edit mr-2"></i> Save
        </fd-button>
        <Draggable
          v-model="bannerList"
          tag="ul"
          class="p-0"
          v-bind="dragOptions"
          handle=".handle"
          @start="dragging = true"
          @end="dragging = false"
        >
          <transition-group
            type="transition"
            :name="!dragging ? 'flip-list' : null"
          >
            <app-banner-card
              v-for="banner in bannerList"
              :key="banner.id"
              :banner="banner"
              :sortable="sortMode"
              @view-image="viewFullImage"
              @edit="editBanner"
              @delete="deleteBanner"
            ></app-banner-card>
          </transition-group>
        </Draggable>
      </div>

      <!-- Banner Lightbox -->
      <cool-light-box
        :items="bannerImage"
        :index="bannerImageIndex"
        @close="bannerImageIndex = null"
        :slideshow="false"
      >
      </cool-light-box>
    </div>

    <modal v-model="bannerEditor.isShown">
      <app-banner-editor
        :isEdit="bannerEditor.isEdit"
        :banner="bannerEditor.bannerToEdit"
        @cancel="bannerEditor.isShown = false"
        @submit="handleBannerFormSubmit"
      ></app-banner-editor>
    </modal>
  </div>
</template>

<script>
import { appBanner as bannerAPI } from "@/api";
import Draggable from "vuedraggable";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";

export default {
  components: {
    AppBannerCard: () => import("@/components/AppBanner/BannerCard"),
    AppBannerEditor: () => import("@/components/AppBanner/BannerEditor"),
    Banner: () => import("@/components/GlobalComponents/Banner"),
    ToggleSwitch: () => import("@/components/GlobalComponents/ToggleSwitch"),
    Draggable
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      sortMode: false,
      dragging: false,
      dragOptions: {
        animation: 200,
        group: "banner",
        disabled: false,
        ghostClass: "ghost"
      },

      bannerImage: [],
      bannerImageIndex: null,

      banners: [],
      initialPositions: [],

      isLoading: false,

      bannerEditor: {
        isShown: false,
        isEdit: false,
        bannerToEdit: {}
      }
    };
  },
  computed: {
    bannerList: {
      get() {
        return sortBy(this.banners, ["position"]).map((banner, index) => {
          banner.position = index + 1;
          return banner;
        });
      },
      set(val) {
        val.forEach((banner, index) => {
          let newPosition = index + 1;
          let foundIndex = this.banners.findIndex((el) => el.id === banner.id);
          this.banners[foundIndex].position = newPosition;
        });
      }
    },
    hasRepositioned() {
      let currentPositions = this.banners.map((banner) => banner.position);

      return !isEqual(this.initialPositions, currentPositions);
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initBanners();
  },
  methods: {
    async initBanners() {
      try {
        this.isLoading = true;
        await this.getBanners();
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async getBanners() {
      try {
        let data = await bannerAPI.getBanners();
        this.initialPositions = data.map((banner) => banner.position);
        this.banners = this._.cloneDeep(data);
      } catch (error) {
        console.error(error);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to load banner list. Please try again later."
        });
        throw error;
      }
    },
    addNewBanner() {
      this.bannerEditor.isEdit = false;
      this.bannerEditor.isShown = true;
    },
    editBanner(banner) {
      this.bannerEditor.bannerToEdit = banner;
      this.bannerEditor.isEdit = true;
      this.bannerEditor.isShown = true;
    },
    viewFullImage(url) {
      this.bannerImage = [url];
      this.bannerImageIndex = 0;
    },
    handleBannerFormSubmit(payload) {
      if (this.bannerEditor.isEdit) {
        this.updateBanner(payload);
      } else {
        this.createBanner(payload);
      }
    },
    async createBanner(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await bannerAPI.createBanner(payload);
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Banner has been created successfully."
        });
        this.bannerEditor.isShown = false;

        await this.initBanners();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to create a new banner. Please try again later."
        });
      }
    },
    async updateBanner(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await bannerAPI.updateBanner(
          this.bannerEditor.bannerToEdit.id,
          payload
        );
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Banner has been updated successfully."
        });
        this.bannerEditor.isShown = false;

        await this.initBanners();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update the banner. Please try again later."
        });
      }
    },
    async deleteBanner(id) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Delete Banner",
        message:
          "Are you sure you want to delete this banner? This action cannot be undone.",
        confirmText: "Delete"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await bannerAPI.deleteBanner(id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Banner has been deleted successfully."
          });
          this.$store.commit("setIsLoading", false);

          await this.initBanners();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to delete the banner. Please try again later."
          });
        }
      }
    },
    resetPosition() {
      this.initialPositions.forEach((position, index) => {
        this.banners[index].position = position;
      });
    },
    async saveNewPosition() {
      try {
        this.$store.commit("setIsLoading", true);
        let payload = this.bannerList.map((banner) => ({
          id: banner.id,
          position: banner.position
        }));
        await bannerAPI.sortBanner(payload);
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Adjusted position of banners has been saved."
        });
        this.$store.commit("setIsLoading", false);
        this.initBanners();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to save position of banners. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-app-banners {
  .banner-warn {
    font-size: 16px;
    font-weight: normal;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
  }
}
</style>
